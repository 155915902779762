/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, FullmapCover, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--60 pt--60" name={"7aud1sm7gd"} style={{"backgroundColor":"#f0e9dc"}}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"backgroundColor":"rgba(240,233,220,1)"}} columns={"2"}>
            
            <ColumnWrapper style={{"backgroundColor":"rgba(240,233,220,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/30556/122458c50b9d4985a9831db67972b784_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":496}} srcSet={"https://cdn.swbpg.com/t/30556/122458c50b9d4985a9831db67972b784_s=350x_.JPG 350w, https://cdn.swbpg.com/t/30556/122458c50b9d4985a9831db67972b784_s=660x_.JPG 660w, https://cdn.swbpg.com/t/30556/122458c50b9d4985a9831db67972b784_s=860x_.JPG 860w, https://cdn.swbpg.com/t/30556/122458c50b9d4985a9831db67972b784_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--3 fs--62 w--400 lh--1" style={{"maxWidth":640}} content={"<span style=\"color: rgb(49, 84, 56);\">2 x 6 lakásos társasház</span>"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(49, 84, 56);\">Balatonakarattya Iskola utca 27.&nbsp;Hrsz.: 2918\n<br>D and H Bau Solution Zrt</span>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"services"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Title className="title-box" style={{"maxWidth":748}} content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\">Műszaki paraméterek</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/30556/25d013ce3ea64d8ebc2f942500cc098e_s=660x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":770}} srcSet={"https://cdn.swbpg.com/t/30556/25d013ce3ea64d8ebc2f942500cc098e_s=350x_.png 350w, https://cdn.swbpg.com/t/30556/25d013ce3ea64d8ebc2f942500cc098e_s=660x_.png 660w, https://cdn.swbpg.com/t/30556/25d013ce3ea64d8ebc2f942500cc098e_s=860x_.png 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/30556/efa72c433770442d9d84f01e0bedd172_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/30556/efa72c433770442d9d84f01e0bedd172_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"references-2"} style={{"backgroundColor":"var(--color-blend-95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 mt--0 flex--top" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Title className="title-box mt--06" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\">Lakás L1&nbsp;</span>"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/o/30556/7d2e0bb3e3c94902a23a9f668a0f83e7.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\"><a href=\"https://ingatlan.com/33575580\" target=\"_blank\">Ingatlan.com link</a></span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/30556/b4a29eb5281649279013a7a09303fe22_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/b4a29eb5281649279013a7a09303fe22_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Title className="title-box mt--06" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\">Lakás L2</span>"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/o/30556/4cd15545cda24a35a56ffdff870c82dd.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(49, 84, 56);\"><a href=\"https://ingatlan.com/33575567\" target=\"_blank\" style=\"\">Ingatlan.com link</a></span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/30556/f8c0b149909f484587c9d376c883f8e9_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/f8c0b149909f484587c9d376c883f8e9_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Title className="title-box mt--06" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\">Lakás L3</span>"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/o/30556/a54814bff37a43f79a98f2ae8e51f9aa.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\"><a href=\"https://ingatlan.com/33575588\" target=\"_blank\">Ingatlan.com link</a></span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/30556/f389bb251bd045e58fab1d732ef7a5c8_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/f389bb251bd045e58fab1d732ef7a5c8_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"67pdpy55bv"} style={{"backgroundColor":"#f0e9dc"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/30556/369b633d302c44b895277c1ba7b837f8_s=660x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/369b633d302c44b895277c1ba7b837f8_s=350x_.JPG 350w, https://cdn.swbpg.com/t/30556/369b633d302c44b895277c1ba7b837f8_s=660x_.JPG 660w, https://cdn.swbpg.com/t/30556/369b633d302c44b895277c1ba7b837f8_s=860x_.JPG 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/30556/c89fa4ff467441939c20943ccec34453_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/c89fa4ff467441939c20943ccec34453_s=350x_.JPG 350w, https://cdn.swbpg.com/t/30556/c89fa4ff467441939c20943ccec34453_s=660x_.JPG 660w, https://cdn.swbpg.com/t/30556/c89fa4ff467441939c20943ccec34453_s=860x_.JPG 860w, https://cdn.swbpg.com/t/30556/c89fa4ff467441939c20943ccec34453_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/30556/41e3c788efc145e188a3e584f9687b53_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/41e3c788efc145e188a3e584f9687b53_s=350x_.JPG 350w, https://cdn.swbpg.com/t/30556/41e3c788efc145e188a3e584f9687b53_s=660x_.JPG 660w, https://cdn.swbpg.com/t/30556/41e3c788efc145e188a3e584f9687b53_s=860x_.JPG 860w, https://cdn.swbpg.com/t/30556/41e3c788efc145e188a3e584f9687b53_s=1400x_.JPG 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/30556/99a0a21b9f244f899048b12fcbdfbe3a_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30556/99a0a21b9f244f899048b12fcbdfbe3a_s=350x_.png 350w, https://cdn.swbpg.com/t/30556/99a0a21b9f244f899048b12fcbdfbe3a_s=660x_.png 660w, https://cdn.swbpg.com/t/30556/99a0a21b9f244f899048b12fcbdfbe3a_s=860x_.png 860w, https://cdn.swbpg.com/t/30556/99a0a21b9f244f899048b12fcbdfbe3a_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"692ugu486pg"} style={{"backgroundColor":"#e2d5be"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"font-weight: bold; color: rgb(49, 84, 56);\">Jelenlegi állapot</span>"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(49, 84, 56);\">&nbsp;2023 év végéig befejeződött az A épület alaplemezének kialakítása, elkészült a falazás, és jelenleg a földszinti mennyezet zsaluzása zajlik<br>Tervezett átadás: 2024 Q4</span><br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/30556/0f162495908f43d5a9e137ae2d1fe75a_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":339}} srcSet={"https://cdn.swbpg.com/t/30556/0f162495908f43d5a9e137ae2d1fe75a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30556/0f162495908f43d5a9e137ae2d1fe75a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30556/0f162495908f43d5a9e137ae2d1fe75a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/30556/0f162495908f43d5a9e137ae2d1fe75a_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/30556/2f8a6b0525ec4bb7b94538c768062a12_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":338}} srcSet={"https://cdn.swbpg.com/t/30556/2f8a6b0525ec4bb7b94538c768062a12_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30556/2f8a6b0525ec4bb7b94538c768062a12_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30556/2f8a6b0525ec4bb7b94538c768062a12_s=860x_.jpg 860w, https://cdn.swbpg.com/t/30556/2f8a6b0525ec4bb7b94538c768062a12_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/30556/82d71b6e06e54451961e4a4a93bef064_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":338}} srcSet={"https://cdn.swbpg.com/t/30556/82d71b6e06e54451961e4a4a93bef064_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30556/82d71b6e06e54451961e4a4a93bef064_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30556/82d71b6e06e54451961e4a4a93bef064_s=860x_.jpg 860w, https://cdn.swbpg.com/t/30556/82d71b6e06e54451961e4a4a93bef064_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"contact"} style={{"backgroundColor":"#e2d5be"}}>
          
          <ColumnWrap className="column__flex el--2 pb--60 pt--60" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\">Kapcsolat</span>"}>
              </Title>

              <Text className="text-box fs--20 ls--0 lh--16" content={"<span style=\"color: rgb(49, 84, 56);\">+36303776316<br>info@dhbau.hu<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <FullmapCover zoom={"14"} place={"Balatonkenese, Iskola u. 27, 8172"}>
              </FullmapCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"mkjkocfm6x"} style={{"backgroundColor":"#e2d5be"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"font-weight: bold; color: rgb(49, 84, 56);\">Lokáció</span>"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/30556/f37ccc7936814df993497df7496cd2b0_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":957}} srcSet={"https://cdn.swbpg.com/t/30556/f37ccc7936814df993497df7496cd2b0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30556/f37ccc7936814df993497df7496cd2b0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30556/f37ccc7936814df993497df7496cd2b0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/30556/f37ccc7936814df993497df7496cd2b0_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom pb--80 pt--80" name={"contact-2"} style={{"backgroundColor":"#315438"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--25 pt--25" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style=\"color: rgb(240, 233, 220);\">Ajánlatot kérek!</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Teljes név"},{"name":"Telephone number","type":"text","required":false,"placeholder":"Telefonszám"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"E-mail cím"},{"name":"Info","type":"textarea","placeholder":"Üzenet"},{"name":"Küldés","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"1rlt4g6lu1y"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(49, 84, 56); font-weight: bold;\">Akarattya Homes</span><br>"}>
              </Title>

              <Title className="title-box fs--16" content={"<span style=\"color: rgb(49, 84, 56); text-decoration-line: underline;\"><a href=\"/adatvedelmi\">Adatvédelmi tájékoztató</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" content={"facebook"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}